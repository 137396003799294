import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MultivendorCommerceItem from './MultivendorCommerceItem';
import '../../assets/css/BranchOfficesList.scss';

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

const MultivendorList = ({ directory, commerces }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [branchOfficesData, setBranchOfficesData] = useState([]);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          setUserLocation(null);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation && commerces?.length > 0) {
      const updatedComercios = commerces
        .map((item) => ({
          ...item,
          toDistance: getDistanceFromLatLonInKm(
            userLocation.latitude,
            userLocation.longitude,
            item?.geolocalizacion?.latitud || 35.6979235,
            item?.geolocalizacion?.longitud || 139.7731759,
          ),
        }))
        .sort((a, b) => a.toDistance - b.toDistance);

      setBranchOfficesData(updatedComercios);
    } else if (commerces?.length > 0) {
      setBranchOfficesData(commerces);
    }
  }, [userLocation, commerces]);

  const handleBranchOffice = (idpath) => {
    window.location.assign(`${window.location.origin}/${idpath}`);
  };
  return (
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <div style={{ maxWidth: '200px', margin: '0 auto' }}>
        <img
          src={directory.logotipo}
          alt={`Logo de ${directory.nombre}`}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
      <h3 className="title_multi">¿Qué quieres comer hoy?</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
        {branchOfficesData &&
          branchOfficesData.map((commerce) => (
            <MultivendorCommerceItem
              onClick={() => handleBranchOffice(commerce.id_path)}
              key={commerce.id_path}
              commerce={commerce}
              toDistance={
                userLocation &&
                commerce?.geolocalizacion?.latitud &&
                commerce?.geolocalizacion?.longitud &&
                (commerce?.toDistance || commerce?.toDistance === 0)
                  ? Number(commerce.toDistance)
                  : null
              }
            />
          ))}
      </div>
    </div>
  );
};

export default MultivendorList;

MultivendorList.propTypes = {
  directory: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    logotipo: PropTypes.string.isRequired,
  }).isRequired,
  commerces: PropTypes.arrayOf(
    PropTypes.shape({
      id_path: PropTypes.string.isRequired,
      cover: PropTypes.string.isRequired,
      logotipo: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
